@import "~@flaticon/flaticon-uicons/css/all/all";

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  top: 110px; /* Adjust as necessary */
}

.content {
  margin: 10px;
}

.inputStyle {
  margin-bottom: 15px;
  padding: 14px;
  width: calc(100% - 40px);
  border-radius: 10px;
  border: 0.7px solid gray; /* Use appropriate color from your theme */
}

.button {
  padding: 14px;
  width: calc(100% - 40px);
  border-radius: 10px;
  background-color: #F9ABAE; /* Use appropriate color from your theme */
  border: none;
  color: white;
  font-size: 15px; /* Use appropriate font size from your theme */
  font-weight: 600;
  cursor: pointer;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.news-app-promo {
  box-sizing: border-box;
  background-color: #000;
  padding: 0.5em;
  margin-top: 1em;
  padding-bottom: 40px;
}

.news-app-promo__section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 8px;
}

.news-app-promo-text {
  color: #fff;
  font-family: helvetica;
  min-width: 277px;
  padding: 0 1em;
  width: 35%;
  margin: 1em auto;
  display: block;
}

.news-app-promo-text__tagline {
  font-size: 1.09em;
}

.news-app-promo-text__download {
  font-size: 2.25em;
  font-weight: 600;
}

.news-app-promo-buttons {
  margin: 0 auto;
  max-width: 35%;
  display: block;
}

.news-app-promo-buttons__buttons {
  display: block;
}

.news-app-promo-buttons__logo {
  display: inline-block;
}

.news-app-promo-subsection {
  display: inline-block;
  margin-right: 10px;
}

.news-app-promo__bbc-logo {
  display: inline-block;
  width: 106px;
  margin-bottom: 8px;
}

.news-app-promo__play-store,
.news-app-promo__app-store {
  display: block;
  width: 161px;
  height: auto;
  margin-bottom: 8px;
}

.news-app-promo-subsection--link {
  text-decoration: none;
  border: 0;
}